import React,{ useEffect, useState, useRef } from "react";
import logo from './qr-logo.png';

import QRCodeStyling from "qr-code-styling";
import './index.css'

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  // image:
  //   logo,
  dotsOptions: {
    color: "#000",
    type: "rounded"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  }
});

function QrCodeGenerator() {
  const [url, setUrl] = useState("https://www.digma.io");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  const qrCodeRef = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt
    });
  };

  return (
    <div className="qrcode__container">
      <h3>Free QR Code Generator</h3>
      <p className="text-center">Feel free to generate your static qr codes here.</p>
      <div className="qrcode__container--parent" >
        <div className="qrcode__input">
          <input value={url} onChange={onUrlChange} />
        </div>
      </div>
      <div className="mt-5" ref={ref} /> 
      <div className="qrcode__download"> 
      {/* <select onChange={onExtensionChange} value={fileExt}>
            <option value="png">PNG</option>
            <option value="jpeg">JPEG</option>
            <option value="webp">WEBP</option>
          </select> */}
        <button className="btn bg-link text-white btn-xl h-55 rounded-5" onClick={onDownloadClick}>Download</button>
        <p className="mt-10">Now Try our <a href="https://app.digma.io/?mode=login">Dynamic QR Codes</a> for advanced targeting and tracking.</p>
      </div>
    </div>
  );
}


export default QrCodeGenerator;