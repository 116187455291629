import React from "react";
import imgQr from "../../assets/qr-images/digma.jpg";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-lg-10 align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-7 col-xs-10"
            data-aos="fade-left"
            data-aos-delay={500}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h4 className="font-size-10 mb-2">got <span className="Links">Links?</span></h4>
              <p className="font-size-6 mb-0">
              Conduct analysis of campaigns, including number of scans, operating systems, geo-tracking, and exports. Construct custom dynamic QR codes with colors, shapes, and logos to match your company’s needs.
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Fast information retrieval</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Promote sharing and networking</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Enhance your SMO and SEO</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Connect your offline and online media</td>
              </tr>                                            
            </table>              
              <div className="mt-9">
                <a className="btn bg-link text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-xs-9"
            data-aos="fade-right"
            data-aos-delay={500}
          >
            <div className="mt-5 mt-lg-10">
              <div className="img-2">
                <img className="w-100 rounded-10" src={imgQr} alt="" />
              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
