import React from "react";
import imgDigmaLinks from "../../assets/image/links/digma-links.png";


const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
      <div className="row pt-lg-10 align-items-center justify-content-center">
          <div
            className="order-lg-1 order-2 col-xl-5 col-lg-6 col-md-7 col-xs-10"
            data-aos="fade-right"
            data-aos-delay={500}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
                Dynamic <span className="Links">Qr Codes</span>
              </h2>
              <p className="font-size-6 mb-0">
              Digma provides a comprehensive link management platform for the creation, customization, delivery, and analytics of URL links. 
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Campaign tracking</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Mobile targeting</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Geo targeting</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-link border-0 text-white" /></td>
                <td>Temporary Url</td>
              </tr>                                       
            </table>               
              <div className="mt-9">
                <a className="btn bg-link text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
          <div
            className="order-lg-2 order-1 col-xl-7 col-lg-6 col-xs-9"
            data-aos="fade-left"
            data-aos-delay={500}
          >
            <div className="mt-lg-0">
              <div className="img-2">
                <img className="w-100 rounded-10" src={imgDigmaLinks} alt="qr code" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
