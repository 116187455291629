import React from 'react';
import PageWrapper from "../components/PageWrapper";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ShortLinks from "../sections/links/ShortLinks";
import QrCode from "../sections/links/QrCode";
import Commit from "../sections/common/Commit";
import Pricing from "../sections/home4/Pricing";
import WaveReverse from "../sections/common/WaveReverse";
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/meta-links.jpg'
import QrCodeGenerator from "../components/QrGenerator";

const Demo = () => {
  return (  
    <>
   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          version: 'links-version',
          footerStyle: "digma",
        }}>
        <SEO
          title="Digma: Links - URL shortener &amp; advanced QR features"
          description="Digma provides a comprehensive short link management platform, QR-code generator with advanced analytics."
          image={metaImg}
        />         
        {/* <Hero /> */}
        <div className="container pt-15 mt-15" id="demo">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="mb-5">Dynamic QR code generator <span className="Links">+</span> Link management</h1>
              <p className="mb-0">All-In-One Platform</p>  
            </div>
          </div>
        </div>      
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <QrCode className="grey pt-0 pb-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />  
        <QrCodeGenerator/>       
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <ShortLinks className="grey pt-0 pb-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />         
        <Pricing />
        <div className='d-none d-md-block grey text-center pb-10 pt-5'>
          <AniLink className="font-size-7" paintDrip hex="#2196f3" to="/pricing">
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
        

      </PageWrapper>
    </>
  );
};
export default Demo;
